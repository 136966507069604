import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MnDropdownComponent } from '@app-shared/component/dropdown';
import { MDModalModule, MDModalsComponent } from '@app-shared/component/modals';
import { NGXPagination } from '@app-shared/component/pagination';
import { OrgclientService } from '@app/core/services/orgclient.service';
import { DeactivateClientComponent } from '@app/pages/deactivate-client/deactivate-client.component';
import { GenerateClientComponent } from '@app/pages/generate-client/generate-client.component';
import { ColumnMode, DatatableComponent, NgxDatatableModule } from '@siemens/ngx-datatable';
import { DisplayBlock } from './../../../core/helpers/helper.classes';


import { HttpClient } from '@angular/common/http';
import { getFileName, parseDateWithoutTimezone, showAlertMessage } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { AdminCenterService } from '@app/core/services/admin-center.service';
import { AuthenticationService } from '@app/core/services/auth.service';
import { FileUploadService } from '@app/core/services/fileupload.service';
import { LeagueService } from '@app/core/services/league.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { TeamService } from '@app/core/services/team.service';
import { SwalService } from '@app/layouts/sidebar/swalService';
import { NgSelectModule } from '@ng-select/ng-select';
import { LucideAngularModule } from 'lucide-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableModel } from '../../../core/helpers/helper.classes';
import { Page } from '../paging/model/page';

@Component({
  selector: 'app-datatable',
  standalone: true,
  imports: [
    NgxDatatableModule,
    NGXPagination,
    FormsModule,
    CommonModule,
    MDModalModule,
    RouterModule,
    MnDropdownComponent,
    GenerateClientComponent,
    DeactivateClientComponent,
    LucideAngularModule,
    PipesModule,
    NgSelectModule
  ],
  templateUrl: './datatable.component.html',
  providers: [DatePipe],
  styleUrls: ['./datatable.component.scss'],
})

export class _DatatableComponent implements OnInit, OnChanges {


  @ViewChild(DatatableComponent) table!: DatatableComponent;
  @ViewChild('openModal') openModal!: MDModalsComponent;
  @ViewChild('viewDocuments') viewDocuments: MDModalsComponent | any;
  @ViewChild('dropdown') dropdown!: MnDropdownComponent;

  @Input() configDataTable: DataTableModel = new DataTableModel();
  // @Input() searching !: boolean;
  @Input() plushButton !: boolean;
  @Input() plusButtonText !: string;
  @Input() displayBlock!: DisplayBlock;


  @Output() childClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleAddBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleDeleteRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleEditRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionsButtonClick = new EventEmitter<{ type: string, row: any }>();

  customMessages = {
    emptyMessage: 'No Data Found'
  };

  page = new Page();
  rows: any;
  datatable: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  startIndex: number = 0;
  endIndex: any;
  columns!: any[];
  ColumnMode = ColumnMode;
  isSearching: boolean = false;
  isFilterOption: boolean = false;
  isAddBtn: boolean = false;
  isViewDocument: boolean = false;
  isExportData: boolean = false;
  isComingFrom!: string;
  headerHeight: number = 0;
  getUrl: string = '';
  rolesData: any = ""
  status = '';
  clientID = '';
  rolesOption: any = []
  selectedRoles: number[] = [];
  globalFilterString: string = "";
  orgClients: any[] = [];
  selectedOrgIds: number[] = [];
  isFilterOrg: boolean = false;
  isMultiselectOrg: boolean = false;
  isDeleteModal: boolean = false;
  selectedRow: any = null;
  loadingIndicator: boolean = true;
  type: string = '';
  selectedLeagueIds: number[] = [];
  selectedAgeGroupIds: number[] = [];
  leagues: any[] = [];
  ageGroupData: any[] = []
  rosterDocList: any[] = [];
  userId: any = 0
  teamDocList: any[] = [];
  id: any;
  typeOfDoc: any;
  leagueId: any;
  constructor(
    private sidebarService: SidebarService,
    private adminCenterService: AdminCenterService,
    private orgclientService: OrgclientService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private swalService: SwalService,
    private leagueService: LeagueService,
    private ngxSpinnerService: NgxSpinnerService,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private http: HttpClient,
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.leagueId = parseInt(this.route.snapshot.queryParamMap.get('leagueId') ?? '0');
  }

  ngOnInit(): void {
    this.reloadData();
    this.sidebarService.getAllRoles().subscribe(
      (data) => {
        this.rolesData = data?.data
      },
      (error) => {

      }
    );

    this.adminCenterService.getAllRolesForUserListPage().subscribe((data: any) => {
      this.rolesOption = data?.data
    }, (error: any) => {
      console.log(error)
    })
    this.getOrgClients();
    if (this.displayBlock?.type === 'registeredTeam') {
      this.getAgeGroups()
    }
    this.getLeagueByOrg('');

  }

  getOrgClients() {
    this.authenticationService.userMe().subscribe((response: any) => {
      if (response?.status) {
        this.userId = response?.data?.id
        const allOrgs = response?.data?.organizations;
        this.orgClients = allOrgs?.filter((item: any) => item.roles.some((role: any) => role?.type === "admin"));
      }
    })
  }

  getAgeGroups() {
    this.leagueService.getLeagueById(this.displayBlock?.key).subscribe((response: any) => {
      if (response?.status) {
        this.ageGroupData = response?.data?.ageGroups;
      }
    })
  }

  getLeagueByOrg(ids: any) {
    const payload = {
      orgIds: ids,
      skip: 0,
      limit: 1000,
      isActive: true
    }
    this.ngxSpinnerService.show();
    this.leagueService.getLeagueList(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        this.leagues = response?.data?.docs ?? [];
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
  }

  plusButtonClick(text: string) {
    // if (text === "Add event schedule") {
    //   this.router.navigate(['/add-event-schedule']
    //     // , { queryParams: { eventSchedule: 'eventSchedule' } }
    //   )
    // }
    this.handleAddBtn.emit()
  }

  onRoleSelectionChange() {
    this.globalFilterString = this.selectedRoles.join(',');
    this.page.role = this.globalFilterString;
    this.startIndex = 0;
    this.reloadData();
  }

  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.page.search = val;
    this.startIndex = 0;
    this.reloadData();
  }

  handle_ORG_Click(event: any, row: any) {
    this.childClick.emit(row); // Wrap the arguments in an object
    //this.childClick.emit(event, row);
  }

  reloadData() {
    this.setPage({ offset: this.startIndex });
    this.dataTableBindind();
  }

  setPage(pageInfo: any) {
    this.page.pageNumber = this.currentPage - 1;
    this.page.offset = pageInfo.offset;
    this.loadingIndicator = true;
    this.rows = [];
    //this.page.limit = pageInfo.limit;
    if (this.displayBlock !== undefined) {
      this.orgclientService.getResults(this.page, this.displayBlock).subscribe(pagedData => {
        this.page = pagedData.page;
        // this.rows = [];
        this.rows = pagedData.data[0];
        this.loadingIndicator = false;
        this.totalItems = this.page.totalElements;
        this.currentPage = this.page.pageNumber;
        this.itemsPerPage = this.page.limit;
      },
        (error: any) => {
          this.loadingIndicator = false;
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  dataTableBindind() {
    this.columns = this.configDataTable?.displayBlock?.rows ?? [];
    this.displayBlock = this.configDataTable?.displayBlock;
    this.itemsPerPage = this.displayBlock?.perPage ?? 5;
    this.page.size = this.displayBlock?.perPage ?? 5;
    this.page.limit = this.displayBlock?.perPage ?? 5;
    this.isSearching = this.displayBlock?.isSearching ?? false;
    this.isComingFrom = this.displayBlock?.isComingFrom ?? "";
    this.isFilterOption = this.displayBlock?.isFilterOption ?? false;
    this.isAddBtn = this.displayBlock?.isAddBtn ?? false;
    this.isViewDocument = this.displayBlock?.isViewDocument ?? false;
    this.isExportData = this.displayBlock?.isExportData ?? false;
    this.headerHeight = this.displayBlock?.headerHeight ?? 0;
    this.getUrl = this.displayBlock?.getUrl ?? '';
    this.isFilterOrg = this.displayBlock?.isFilterOrg ?? false;
    this.isMultiselectOrg = this.displayBlock?.isMultiselectOrg ?? false;
    this.isDeleteModal = this.displayBlock?.isDeleteModal ?? false;
    this.type = this.displayBlock?.type ?? '';
    this.id = this.displayBlock?.id ?? '';
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.updatePagedOrders();
  }

  getEndIndex() {
    return Math.min(this.startIndex + this.itemsPerPage, this.totalItems);
  }

  updatePagedOrders(): void {
    this.startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.endIndex = this.startIndex + this.itemsPerPage;
    this.dataTableBindind();
    this.setPage({ offset: this.startIndex, limit: this.endIndex });
    // this.datatable = dataTable.slice(this.startIndex, this.endIndex);
  }

  // onTreeAction(event: any) {
  //   const index = event.rowIndex;
  //   const row = event.row;
  //   if (row.treeStatus === 'collapsed') {
  //     row.treeStatus = 'expanded';
  //   } else {
  //     row.treeStatus = 'collapsed';
  //   }
  //   this.rows = [...this.rows];
  // }

  handleChangeOrganizations(event: any) {
    if (this.type === 'teamSubmission') {
      if (event.length != 0) {
        //this.leagues = [];
        this.selectedLeagueIds = [];
        this.page.leagueIds = '';
        if (typeof this.selectedOrgIds === 'number' || typeof this.selectedOrgIds === 'string') {
          this.page.org = this.selectedOrgIds;
          this.getLeagueByOrg(this.selectedOrgIds);
        } else {
          this.page.orgIds = this.selectedOrgIds.join(',');
          this.getLeagueByOrg(this.selectedOrgIds.join(','));
        }
        // this.selectedLeagueIds = [];
      } else {
        // this.leagues = [];
        this.selectedLeagueIds = [];
        this.page.leagueIds = '';
      }
    } else {
      if (event) {
        if (typeof this.selectedOrgIds === 'number' || typeof this.selectedOrgIds === 'string') {
          this.page.org = this.selectedOrgIds;
        } else {
          this.page.orgIds = this.selectedOrgIds.join(',');
        }
      } else {
        this.page.org = '';
      }
    }
    this.startIndex = 0;
    this.reloadData();

  }
  handleChangeLeague(event: any) {
    if (event) {
      if (typeof this.selectedLeagueIds === 'number' || typeof this.selectedLeagueIds === 'string') {
        this.page.leagueIds = this.selectedLeagueIds;
      } else {
        this.page.leagueIds = this.selectedLeagueIds.join(',');
      }
    } else {
      this.page.leagueIds = '';
    }

    this.startIndex = 0;
    this.reloadData();
  }

  handleChangeAgeGroup(event: any) {
    if (event) {
      if (typeof this.selectedAgeGroupIds === 'number' || typeof this.selectedAgeGroupIds === 'string') {
        this.page.agegroupIds = this.selectedAgeGroupIds;
      } else {
        this.page.agegroupIds = this.selectedAgeGroupIds.join(',');
      }
    } else {
      this.page.agegroupIds = '';
    }

    this.startIndex = 0;
    this.reloadData();
  }

  editRow(row: any) {
    this.handleEditRow.emit(row);
    // this.startIndex = 0;
    // this.reloadData();
  }

  deleteRow(row: any) {
    this.swalService.conformationPopup('Are you sure you want to delete?', 'none', () => {
      this.handleDeleteRow.emit(row);
      this.startIndex = 0;
      this.reloadData();
    }, () => { }, '', 'Yes, Delete', 'Cancel',);
  }

  deleteRowForModal(row: any) {
    this.handleDeleteRow.emit(row);
  }

  trainingDateFormate(date: any) {
    return parseDateWithoutTimezone(date, 'MM/DD/YYYY');
  }

  viewTeamRoster(routingUrl: string, row: any) {
    this.router.navigate([routingUrl]
      , { queryParams: { teamId: row?.team?.id, teamRequestId: row?.id } }
    )
  }

  viewLeagueTeamRoster(routingUrl: string, row: any) {
    this.router.navigate([routingUrl]
      , { queryParams: { teamId: row?.id, leagueId: this.leagueId } }
    )
  }
  viewDocument(row: any, type: any) {
    if (type == 'teamDoc') {
      this.typeOfDoc = type;
      this.getTeamDocumentList(this.id)
    } else {
      this.typeOfDoc = type;
      this.getRosterDocumentList(row.athlete.id)
    }
  }
  getRosterDocumentList(id: any) {
    this.rosterDocList = [];
    this.ngxSpinnerService.show();
    this.teamService.getRosterDocuments(id).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        this.rosterDocList = response?.data;
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
    this.viewDocuments.open();
  }
  getTeamDocumentList(teamId: any) {
    this.rosterDocList = [];
    this.ngxSpinnerService.show();
    this.teamService.getTeamDetails(teamId).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        const coachWaiver = response?.data?.coachWaivers;
        const teamDocuments = response?.data?.teamDocuments;
        this.rosterDocList = [...teamDocuments];
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
    this.viewDocuments.open();
  }
  closeviewDocuments() {
    this.viewDocuments.close();
  }

  bindArrayData(arrayData: any[]) {
    const filterData = arrayData?.map(team => team?.ageGroup?.name).join(', ');
    return filterData ?? '-';
  }

  onButtonActionClick(type: string, row: any) {
    this.actionsButtonClick.emit({ type, row });
  }

  renderDocFileName(url: string) {
    if (url) {
      return getFileName(url)
    } else {
      return ''
    }
  }

  showTeamDocFile(url: string) {
    window.open(url, '_blank');
  }

  exportData() {
    if (this.selectedOrgIds.length == 0) {
      showAlertMessage("You must select an organization before exporting data.", "error");
      return;
    }
    else if (this.selectedOrgIds.length > 1) {
      showAlertMessage("Only one organization can be exported at a time. Please remove duplicate orgs.", "error");
      return;
    }
    this.ngxSpinnerService.show();
    this.fileUploadService.downloadUserByOrgId(this.selectedOrgIds).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        const url = response?.data;
        this.downloadFile(url);
      } else {
        showAlertMessage(response?.message ?? "Something went wrong!", "error");
      }
    }, () => {
      this.ngxSpinnerService.hide();
    });
  }
  downloadFile(url: string) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = 'teamRoster.xlsx';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }
}
