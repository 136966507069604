import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { format } from 'date-fns';
import moment from "moment";
import Swal from "sweetalert2";

let now = moment();
let CURRENT_TIME = now.format('hh:mm A');
let CURRENT_DATE = now.format("YYYY-MM-DD");
const EMAIL_REG = /^\s*[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})\s*$/;
const PHONE_REG = /^\(\d{3}\)\s\d{3}-\d{4}$/;

function crrDateTimeRecurr() {
  let now = moment();
  CURRENT_TIME = now.format('hh:mm A');
  CURRENT_DATE = now.format("YYYY-MM-DD");
}

setInterval(crrDateTimeRecurr, 10000);

function checkIfUserIsAdminOrParent(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles?.some((role: any) => role.type === 'admin' || role.type === 'parent');
}

function checkIfUserIsAdminOrParentorAthlete(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles?.some((role: any) => role.type === 'admin' || role.type === 'parent' || role.type === 'athlete');
}

function checkFirstOrgID(userDetails: any): boolean {
  return userDetails?.organizations[0]?.id;
}

//check if the role is admin for current org
function checkIfUserIsAdmin(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'admin');
}

function checkIfUserIsParent(userDetails: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  const hasParentRole = organization?.some((org: any) => org.roles.some((role: any) => role.type === 'parent'));
  return hasParentRole;
}

function checkIfUserIsAthlete(userDetails: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  const hasAthleteRole = organization?.some((org: any) => org.roles.some((role: any) => role.type === 'athlete'));
  return hasAthleteRole;
}

function checkIfUserIsAdminInAllOrg(userDetails: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  const hasAdminRole = organization?.some((org: any) => org.roles.some((role: any) => role.type === 'admin'));
  return hasAdminRole;
}

function checkIfUserIsCoach(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'coach');
}

function checkIfUserIsManager(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'manager');
}

function checkIfUserIsAdminOrCoachorManager(): boolean {
  const currentOrgId = getSaveData("activeLinkforOrganization");
  const userDetailsFromUserMe = getSaveData('userDetailsFromUserMe');
  let organization = parentChildOrganization(userDetailsFromUserMe?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == currentOrgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles?.some((role: any) => role.type === 'admin' || role.type === 'coach' || role.type === 'manager');
}

function getSaveData(KEY: string): any {
  const data = localStorage.getItem(KEY);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}


const getFileName = (url: string): string => {
  const match = url.match(/\/([^\/?#]+)[^\/]*$/);
  return match ? match[1] : "";
}

// Create a file object with the image URL
const getImgFileFromFileURL = (imageURL: string, fileName: string): File | null => {
  if (imageURL && fileName) {
    return new File([imageURL], fileName, { type: "image/png" });
  } else {
    return null;
  }
};

const showAlertMessage = (text: string, type: 'success' | 'error', onCloseCallback?: () => void, titleText?: string) => {
  const imageUrl = type === 'success' ? 'assets/images/success@2x.png' : 'assets/images/info.png';
  const textBelowIcon = type === 'success' ? 'Tap anywhere to continue.' : 'Tap anywhere to try again.';
  Swal.fire({
    title: '',
    html: `
      <div style="text-align: center; ">
        <div style="margin-top : 40px; color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${text}</div>
        <div style="display: flex; justify-content: center; margin: 20px 0px 20px 0px"><img style="height: 74px; width: 74px;" src="${imageUrl}" alt="success"/></div>
        <div style=""><p style="font-size : 14px; color: #3B3B3B; font-family: 'Arial', sans-serif;"> ${textBelowIcon} </p> </div>
      </div>
    `,
    icon: undefined,
    showConfirmButton: false,
    animation: false,
    buttonsStyling: false,
    showCloseButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: 'custom-popup-class',
      container: 'custom-container-class'
    },
    didOpen: (popup) => {
      popup.addEventListener('click', () => {
        Swal.clickConfirm();
      });
    },
    didClose: onCloseCallback
  });
}

const formatTime = (value: string): string => {
  if (!value) return '';
  // Parse the input string to get the hours and minutes
  const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
  // Create a Date object to leverage date-fns
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // Format time using date-fns
  return format(date, 'hh:mm a');
}

const formatDate = (value: Date | string): string => {
  if (!value) return '';
  // Parse the input value to ensure it's a Date object
  const date = typeof value === 'string' ? new Date(value) : value;
  return format(date, 'MMMM dd, yyyy');
}

function formatPhoneNumber(phoneNumber: any) {
  return phoneNumber.replace(/\D/g, '');
}

function removeDuplicates(array: any[], key: string) {
  return array.filter((item, index) => {
    // Return the first occurrence of each object based on the specified key
    return index === array.findIndex(obj => obj[key] === item[key]);
  });
}

function timeToMinutes(timeString: string) {
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  let totalMinutes = hours * 60 + minutes;
  if (period === 'PM' && hours !== 12) {
    totalMinutes += 12 * 60; // Convert PM to 24-hour format
  } else if (period === 'AM' && hours === 12) {
    totalMinutes -= 12 * 60; // Convert 12 AM to 0
  }
  return totalMinutes;
}

function compareTimes(start: string, end: string) {
  const startMinutes = timeToMinutes(start);
  const endMinutes = timeToMinutes(end);

  if (startMinutes < endMinutes) {
    return true;
  } else {
    return false;
  }
}

function formatPhoneNumberPattern(number: any) {
  // Remove all non-digit characters from the input string
  const cleaned = number.replace(/\D/g, '');

  // Apply the desired phone number format
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // Return the original value if it doesn't match the expected format
  return number;
}

function formatCurrency(amount: number = 0, currency?: string) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ? currency : "USD",
  });
  return formatter.format(amount);
};

function charValidate(text: string, limit: number): string {
  if (text !== undefined && limit !== undefined) {
    if (text !== null) {
      if (text.length > limit) {
        return `${text.substring(0, limit - 3)}...`;
      } else {
        return `${text.substring(0, limit)}`;
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

function parseDateWithoutTimezone(date: string | Date, format?: string) {
  if (typeof date === 'string') {
    return moment(date).format(format ? format : "YYYY-MM-DD");
  } else {
    return moment(date, 'YYYY-MM-DD').format(format ? format : "YYYY-MM-DD");
  }
};

function dobTimezoneFormat(date: string | Date, format?: string) {
  if (typeof date === 'string') {
    return moment.utc(date).format(format ? format : "YYYY-MM-DD");
  } else {
    return moment(date).format(format ? format : "YYYY-MM-DD");
  }
};

function formatMaskPhoneNumber(number: number | string): string {
  // Convert the number to a string
  let numStr = '';
  if (typeof number === 'number') {
    numStr = number.toString();
  } else {
    numStr = number;
  }
  // Extract the parts of the number
  let areaCode = numStr.substring(0, 3);
  let centralOfficeCode = numStr.substring(3, 6);
  let lineNumber = numStr.substring(6, 10);
  // Format the parts into the desired format
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
}

function downloadFile(url: string) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = 'teamRoster.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
function noLeadingOrTrailingSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}

function extractFileParts(filename: string): string {
  if (filename) {
    // Extract the first 5 characters
    const firstPart = filename.slice(0, 5);
    // Find the position of the last dot
    const dotIndex = filename.lastIndexOf('.');
    // Extract the last 5 characters before the dot
    const lastPart = filename.slice(dotIndex - 5, dotIndex);
    // Extract all characters after the dot
    const extension = filename.slice(dotIndex);
    // Combine the parts
    return `${firstPart}.....${lastPart}${extension}`;
  } else {
    return '';
  }
}

function parentChildOrganization(orgClients: any) {
  let result: any = [];
  orgClients.forEach((client: any) => {
    // Add the parent organization
    result.push({
      id: client.id,
      name: client.name,
      isSubOrganization: false,
      isParent: true,
      roles: client.roles
    });

    // Add the sub-organizations
    client.subOrganizations.forEach((subOrg: any) => {
      result.push({
        id: subOrg.id,
        name: subOrg.name, // Add indentation
        isSubOrganization: true,
        parentOrgId: client.id,
        isParent: false,
        roles: subOrg.roles
      });
    });
  });
  return result;
}

function setVideoThumbnailImage(url: string): string {
  if (url) {
    return url;
  } else {
    return 'assets/images/sketchplay-logo.png';
  }
}


export {
  charValidate, checkFirstOrgID, checkIfUserIsAdmin, checkIfUserIsAdminInAllOrg, checkIfUserIsAdminOrCoachorManager, checkIfUserIsAdminOrParent, checkIfUserIsAdminOrParentorAthlete, checkIfUserIsAthlete, checkIfUserIsCoach,
  checkIfUserIsManager, checkIfUserIsParent, compareTimes, CURRENT_DATE, CURRENT_TIME, dobTimezoneFormat, downloadFile, EMAIL_REG, extractFileParts, formatCurrency, formatDate, formatMaskPhoneNumber, formatPhoneNumber,
  formatPhoneNumberPattern,
  formatTime, getFileName, getImgFileFromFileURL, getSaveData, noLeadingOrTrailingSpacesValidator, parentChildOrganization, parseDateWithoutTimezone, PHONE_REG, removeDuplicates, setVideoThumbnailImage, showAlertMessage
};

